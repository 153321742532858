import React from 'react'
import Header from './Header'
import Container from 'react-bootstrap/Container'
import Footer from './Footer'
import ReactDOM from 'react-dom';

export default class Layout extends React.Component { 
	static defaultProps = {
		imageUrl: null,
		title: null,
		description: null, 
		type: 'curation',
		headerContainerStyle: null,
		useHeaderContainer: true, 
		showHeaderSearch: false,
		noNavPaddingMode: false
	}
	constructor(props){ 
		super(props)
		this.state = { navHeight: 50 };

    	this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() { 
		window.addEventListener('resize', this.handleResize);
    	this.handleResize();
	}

	getPaddingTop() { 
		if (this.props.noNavPaddingMode) { 
			return 0
		} else { 
			return this.state.navHeight;
		}
	}

	handleResize(e = null) {
		const nav = ReactDOM.findDOMNode(this._navbar)
		if (nav !== null) { 
    		this.setState({ navHeight: nav.offsetHeight });
    	}
 	}

 	componentWillUnmount() {
    	window.removeEventListener('resize', this.handleResize);
  	}

	getContainerStyle() { 
		if (this.props.noBackground == true) { 
			return {height: this.props.containerHeight, paddingTop: this.getPaddingTop(), minHeight: 600, marginBottom: 60}
		} else { 
			return {backgroundColor: 'white', height: this.props.containerHeight, paddingTop: this.getPaddingTop(), minHeight: 600, marginBottom: 60}
		}
	}

	getLayoutColor() { 
		if (this.props.overrideStyle) { 
			return this.props.style
		} else { 
			return {backgroundColor:'#f6f7f8'}
		}
	}

	getContainer() { 
		if (this.props.noContainer) { 
			return <div style={{...this.props.style, ...{paddingTop: this.getPaddingTop()}}}>{this.props.children}</div>
		}

		if (this.props.fluid == true) { 
			return (<Container style={{...this.getContainerStyle(), ...this.props.style}} fluid>
		    		{this.props.children}
		    	</Container>)
		} else { 
			return (<Container style={{...this.getContainerStyle(), ...this.props.style}}>
		    		{this.props.children}
		    	</Container>)
		}
	}

	render() {
		return (
			<div style={this.getLayoutColor()}>
		    	<Header
				noDownload={this.props.noDownloadHeader}
				homepage={this.props.homepage}
				canonicalUrl={this.props.canonicalUrl ? this.props.canonicalUrl : this.props.canocUrl}
				// TODO: This should be true by default
				noStripe={this.props.noStripe}
				showEventSearchBar={this.props.showHeaderSearch}
				scrollColorChange={this.props.scrollColorChange}
				type={this.props.type}
				useContainer={this.props.useHeaderContainer}
				containerStyle={this.props.headerContainerStyle}
		    	navRef={(e) => this._navbar = e }
		    	imageUrl={this.props.imageUrl}
		    	title={this.props.title}
		    	description={this.props.description} 
		    	user={this.props.user}
		    	/>
		    	{this.getContainer()}
		    	{!this.props.noFooter && <Footer type={this.props.type}/>}
	  		</div>
	  		)
	}
}

