import Layout from '../../components/Layout'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Row from 'react-bootstrap/Row'
import NoSSR from 'react-no-ssr'
import { CSSProperties, useEffect, useState, forwardRef } from 'react'
import Col from 'react-bootstrap/Col'
import constants from '../../components/Constants'
import { isMobile } from 'react-device-detect'
import Constants from '../../components/Constants'
import MetricsManager from '../../managers/MetricsManager'
import Head from 'next/head'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal'
import Badge from 'react-bootstrap/Badge'
import BeautyStars from 'beauty-stars';
import { Default, Mobile } from '../../components/ViewTypes'
import ReactPixel from 'react-facebook-pixel';
import ZoneAPI from '../../api/ZoneAPI'
import { Carousel } from 'react-bootstrap'
import { GrLocation } from 'react-icons/gr'
import { merge, bounceOutDown, fadeInUp, bounceInUp } from 'react-animations'
import styled, { keyframes, css } from 'styled-components';


const fadeInUpAnimation = keyframes`${fadeInUp}`;

const mixin = css`
animation: 0.8s ${fadeInUpAnimation}
`

const BouncyDiv = styled.div`
${props => props.enabled() ? mixin : 'visibility: hidden'};
x`

const backgroundImages = ["https://www.witheta.com/static/crowd_1.jpg", "https://gameplan-image-urls.s3.amazonaws.com/bay_2.jpg", "https://gameplan-image-urls.s3.amazonaws.com/nyc_crowd.jpg", "https://gameplan-image-urls.s3.amazonaws.com/houston_crowd.jpg"]

const styles = {
    useCaseDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    centerText: {
        textAlign: 'center'
    }
}

const aboveFoldHeight = "750px"

const PASSPORT_GET_STARTED_CLICK = "PASSPORT_GET_STARTED_CLICK";

const pricingPlans = [
    {
        title: "Passport",
        cost: 3,
        features: ["Customized calendar of events, businesses and places to go", "Selection of black-owned restaurants for you to check out"],
        nonFeatures: ["Chat with local curators and get even more insight"],
    },
    {
        title: "VIP",
        cost: 30,
        features: ["Customized calendar of events, businesses and places to go", "Selection of black-owned restaurants for you to check out", "Chat with local curators and get even more insight"],
        nonFeatures: []
    },

]

function getJumboStyle(imageUrl, backgroundPos = "center") {
    const jumbotronStyle = {
        boxShadow: `inset 0 0 0 2000px rgba(0,0,0,0.4)`,
        height: aboveFoldHeight, /* You must set a specified height */
        backgroundSize: 'cover',
        backgroundPosition: backgroundPos,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
        paddingTop: 80,
        backgroundImage: `url(${imageUrl})`,
    }
    return jumbotronStyle
}

const PassportLandingPage = (props) => {
    const metricsManager = new MetricsManager();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(Date.now() + 3 * 24 * 60 * 60 * 1000));
    const [selectedVibes, setSelectedVibes] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedZone, setSelectedZone] = useState(props.zone)
    const [allZones, setAllZones] = useState([])
    const zoneApi = new ZoneAPI();
    const [userY, setUserY] = useState(0);


    const ExampleCustomInput = forwardRef(({ value, onClick, size }, ref) => (
        <Form.Control readOnly style={{ backgroundColor: 'white', borderRadius: 0 }} size={size} onClick={onClick} ref={ref} value={value} placeholder='Select days'></Form.Control>));


    useEffect(() => {
        getAllActiveZones();
        if (selectedZone && selectedZone.slug === "default") {
            setSelectedZone(null);
        }
        ReactPixel.init('1603426606357869');
        ReactPixel.trackCustom("HomepageView", { zone: props.zone ? props.zone.slug : 'la' });

        metricsManager.logEvent("PASSPORT_HOME_PAGE_VIEW", {exp: props.exp, showVideo: props.showVideo});


        var previousY = 0;
        setInterval(() => {
            if (window.scrollY > previousY) {
                console.log(window.scrollY);
                console.log("SETTING USER Y")
                setUserY(window.scrollY)
                previousY = window.scrollY;
            }
        }, 500);
    }, [])

    function handleZoneChange(title) {
        const zone = allZones.find(e => e.name === title);
        if (zone) {
            setSelectedZone(zone)
        }
    }

    async function getAllActiveZones() {
        if (!selectedZone) {
            return;
        }
        const allZones = selectedZone.config['travel']['live_cities']
        console.log(allZones)
        if (allZones) {
            const liveZones = await zoneApi.getZones(allZones)
            if (liveZones && liveZones.code !== -1) {
                setAllZones(liveZones)
            }
        }
    }

    const getInput = (isMobile = false) => {
        return <React.Fragment>
            <div>
                <FormControl as="select" size={isMobile ? "md" : "lg"} style={{
                    color: !selectedZone ? 'gray' : selectedZone.slug === "default" ? 'gray' : 'black',
                    borderRadius: 0
                }}
                    onChange={(e) => handleZoneChange(e.target.value)}>
                    {!selectedZone && <option>{isMobile ? "City.." : "Select city"}</option>}
                    {selectedZone && selectedZone.slug === "default" && <option>{isMobile ? "City.." : "Select city"}</option>}
                    {selectedZone && selectedZone.slug !== "default" && <option>{selectedZone.name}</option>}
                    {allZones.map((item, index) => {
                        if (selectedZone && selectedZone.slug === item.slug) {
                            return null
                        }
                        return <option>{item.name}</option>
                    })}
                </FormControl>
            </div>
            <div>
                <NoSSR>
                    <DatePicker style={{ borderRadius: 20 }}
                        onFocus={(e) => e.target.blur()}
                        selectsRange={true}
                        startDate={dateRange[0]}
                        endDate={dateRange[1]}
                        isClearable={false}
                        placeholderText="Select days"
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        withPortal
                        customInput={<ExampleCustomInput size={isMobile ? "md" : "lg"} />}
                    />
                </NoSSR>
            </div>

            <div style={isMobile ? { display: 'flex', justifyContent: 'center' } : null}>
                <Button size={isMobile ? "md" : "lg"} variant="info" style={{ marginBottom: 0, width: isMobile ? '60px' : null }} onClick={() => {
                    if (selectedZone) {
                        window.sessionStorage.setItem('zone', JSON.stringify(selectedZone));
                    } else {
                        alert("Please select a city!")
                        return;
                    }
                    if (dateRange[0] && !dateRange[1]) {
                        alert("Please select end date");
                        return;
                    }
                    metricsManager.logEvent(PASSPORT_GET_STARTED_CLICK, { source: 'hero' })
                    window.location.href = `/passport/onboard?exp=${props.exp}&startDate=${dateRange[0].toISOString()}&endDate=${dateRange[1].toISOString()}&vibes=${selectedVibes}&zone=${selectedZone.slug}`;
                }}>{isMobile ? "Go" : "Plug me in"}
                </Button>
            </div>
        </React.Fragment>
    }

    return <Layout noContainer noStripe noDownloadHeader scrollColorChange={true} useNavHeight={false} style={{ backgroundColor: 'white' }} noNavPaddingMode
        title="ETA - Tap in to the culture"
        description="ETA is your plug to the best things happening for the culture. Our seasoned guides curate events loved by locals that put you at the heart of the culture. For us by us."
        homepage="/passport">
        <Head>
            <script>{`window['_fs_debug'] = false;
window['_fs_host'] = 'fullstory.com';
window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
window['_fs_org'] = '13M6MK';
window['_fs_namespace'] = 'FS';
(function(m,n,e,t,l,o,g,y){
    if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
    g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
    o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
    y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
    g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
    g.anonymize=function(){g.identify(!!0)};
    g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
    g.log = function(a,b){g("log",[a,b])};
    g.consent=function(a){g("consent",!arguments.length||a)};
    g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
    g.clearUserCookie=function(){};
    g.setVars=function(n, p){g('setVars',[n,p]);};
    g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
    if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
    g._v="1.3.0";
})(window,document,window['_fs_namespace'],'script','user');`}</script>
        </Head>
        <div style={{ width: '100%' }}>
            <div id="video-content" style={{ width: '100%', height: aboveFoldHeight, overflow: 'hidden', backgroundColor: 'black' }}>
            {!props.showVideo && <Carousel controls={false} indicators={false} fade interval={2000} style={{ backgroundColor: 'black', height: aboveFoldHeight }}>
                    {backgroundImages.map((item, index) => <Carousel.Item>
                        <div style={getJumboStyle(item, index === 2 ? 'right' : 'center')}></div>
                    </Carousel.Item>
                    )}
                </Carousel>}

            {props.showVideo && <NoSSR>
                    <video playsInline autoPlay={true} muted loop src={isMobile ? "https://gameplan-image-urls.s3.us-east-2.amazonaws.com/manually_uploaded/ETA-MobileView2.mp4" : "https://gameplan-image-urls.s3.us-east-2.amazonaws.com/manually_uploaded/ETA-Landing2.mp4"} style={{ height: aboveFoldHeight, width: '100%', objectFit: 'cover' }}>
                    </video>
                </NoSSR>}
            </div>
            <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                <Container style={{ position: 'absolute', zIndex: 3, top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', height: aboveFoldHeight }}>
                    <NoSSR>
                        <div style={{ width: '100%', marginTop: 40 }}>
                            <NoSSR>
                                <BouncyDiv enabled={() => true}>
                                    <p style={{ textAlign: 'center', color: Constants.EtaButtonColorBright, marginBottom: 5 }}><small>For Travelers</small></p>
                                    {/* <h1 style={{ color: 'white', fontSize: isMobile ? '2.8em' : '4em', textAlign: 'center', marginBottom: '10px' }}><strong>Find the best <span style={{ color: Constants.EtaButtonColorBright }}>moves</span></strong></h1> */}
                                    <h1 style={{ color: 'white', fontSize: isMobile ? '2.8em' : '4em', textAlign: 'center', marginBottom: '10px' }}><strong>Tap into the <span style={{ color: Constants.EtaButtonColorBright }}>culture</span></strong></h1>

                                    <p style={{ color: 'white', fontSize: isMobile ? '1.0em' : '1.4em', marginBottom: '30px', textAlign: 'center' }}>Powered by local curators, ETA plugs you into the dopest things happening for the culture</p>
                                </BouncyDiv>
                                {!isMobile && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    {getInput(false)}
                                </div>}
                                {isMobile && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>{getInput(true)}</div>}

                            </NoSSR>

                            <BouncyDiv enabled={() => true} style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
                                <div style={{ marginTop: 7 }}>
                                    <BeautyStars
                                        size={14}
                                        value={5}
                                        inactiveColor={'#bbbb'}
                                        gap={2}
                                        editable={false}
                                    />
                                </div>
                                <p style={{ color: 'white', marginLeft: 10, fontSize: '1.2em', textDecoration: "underline" }}><strong>100+ reviews</strong></p>
                            </BouncyDiv>

                            <BouncyDiv enabled={() => true}>
                                <p style={{ color: 'white', textAlign: 'center' }}><small>Join over <span style={{ fontWeight: 'bold' }}>10k</span> people who have been plugged in</small></p>
                            </BouncyDiv>
                        </div>
                    </NoSSR>
                </Container>
            </div>

        </div>

        <NoSSR>
            <Container style={{ marginTop: 40, marginBottom: 100 }}>
                <div style={{ marginTop: '80px', marginBottom: '100px' }}>
                    <BouncyDiv enabled={() => userY > 0}>
                        <p style={{ textAlign: 'center', fontSize: '1.5em' }}>As seen in</p>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                            <div style={{ marginRight: '10%' }}>
                                <img src="/static/afrotech_logo.svg" style={{ width: '140px', textAlign: 'center' }} />
                            </div>
                            <div>
                                <img src="/static/voyagela_logo.png" style={{ width: '140px', textAlign: 'center' }}></img>
                            </div>
                        </div>
                    </BouncyDiv>
                </div>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        {!isMobile && <BouncyDiv enabled={() => userY > 300} style={{ textAlign: 'center' }}>
                            <h1 style={{ textAlign: 'center', fontSize: '3em', marginTop: 30 }}>Experience authentic culture</h1>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <p style={{ maxWidth: isMobile ? '100%' : '50%' }}>ETA partners with in the know local insiders to find and curate the best things happening in hip hop and black culture. Updated hourly.</p>
                            </div>
                        </BouncyDiv>}
                        <BouncyDiv enabled={() => userY > 300} style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={isMobile ? "/static/eta_passport_mobile_1.png" : "/static/eta_passport_1.png"} width={!isMobile ? '120%' : "200%"}
                                style={{ marginBottom: 20, textAlign: 'center' }}></img>
                        </BouncyDiv>
                        {isMobile && <BouncyDiv enabled={() => userY > 300} style={{ textAlign: 'center' }}>
                            <h1 style={{ textAlign: 'center', fontSize: '2em' }}>Experience authentic culture</h1>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <p style={{ maxWidth: isMobile ? '100%' : '50%' }}>ETA partners with in-the-know local insiders to find and curate the best things happening in hip hop and black culture. Updated hourly.</p>
                            </div>
                        </BouncyDiv>}
                    </div>
                </div>
            </Container>
        </NoSSR>

        {/* 
        <Container>
            <div style={{ marginBottom: '40px' }}>
                <h2 style={{ textAlign: 'center', fontSize: '35px' }}>How it works</h2>
            </div>
            <Row style={{ marginBottom: !isMobile ? '20%' : '140px' }}>
                <Col md style={{ marginBottom: 40 }}>
                    <img src="/static/col-1-image.jpg" style={{ maxWidth: '100%', marginBottom: 40 }}></img>
                    <h5><strong>Tell us what you're looking for</strong></h5>
                    <p>Let us know when you’ll come into town and what vibes you’re looking for.</p>
                </Col>
                <Col md style={{ marginBottom: 40 }}>
                    <img src="/static/col-2-image.jpg" style={{ height: 100, marginBottom: 40 }}></img>
                    <h5><strong>We plug you in</strong></h5>
                    <p>Our one-of-kind system powered by local insiders curates a live guide of moves and places for you to visit.</p>
                </Col>
                <Col md style={{ marginBottom: 40 }}>
                    <img src="/static/col-3-image.jpg" style={{ height: 100, marginBottom: 40 }}></img>
                    <h5><strong>Your well seasoned guide is ready</strong></h5>
                    <p>Share your guide with friends and other people you’re traveling with so they can get in on the action too.</p>
                </Col>
            </Row>

        </Container> */}



        <Container style={{ marginTop: 20 }}>
            <NoSSR>
                <Row style={{ display: 'flex', marginBottom: !isMobile ? '15%' : 100, marginTop: '80px' }}>
                    {isMobile && <Col md>
                        <BouncyDiv enabled={() => userY > 1200} style={{
                            backgroundImage: `url(/static/background-black.jpg)`,
                            backgroundSize: 'cover',
                            width: '100%',
                            height: '300px',
                            borderRadius: '20px'
                        }} />
                    </Col>}
                    <Col md style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                        <BouncyDiv enabled={() => userY > 1200}>
                            <p style={{ color: constants.spacesPrimaryColor, marginBottom: 8, marginTop: 20 }}>For the culture by the culture</p>
                            <h2 style={{ fontSize: '2.4em', marginBottom: '20px' }}>Discover the community</h2>
                            <p>From beloved restaurants to locals only bars and gems -- we connect you to the community so you can go deep and make the most of your travel.</p>
                        </BouncyDiv>
                    </Col>
                    {!isMobile && <Col md style={{ textAlign: "center" }}>
                        <BouncyDiv enabled={() => userY > 1200} style={{
                            backgroundImage: `url(/static/background-black.jpg)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            maxWidth: '100%',
                            height: '300px',
                            borderRadius: '20px'
                        }}>
                        </BouncyDiv>
                    </Col>}
                </Row>
            </NoSSR>

            <NoSSR>
                <Row style={{ display: 'flex', marginBottom: !isMobile ? '20%' : 100, marginTop: '80px' }}>
                    <Col md style={{ marginLeft: 20, marginBottom: 20, minHeight: '200px' }}>
                        <BouncyDiv enabled={() => userY > 1700} style={{
                            backgroundImage: `url(/static/black-owned-business.png)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'left',
                            height: '100%',
                            borderRadius: '20px'
                        }}>
                        </BouncyDiv>

                    </Col>
                    <Col md style={{ marginBottom: 100, display: 'flex', justifyContent: "center", minHeight: "100%", alignItems: 'center' }}>
                        <BouncyDiv enabled={() => userY > 1700}>
                            <p style={{ color: constants.spacesPrimaryColor, marginBottom: 8 }}>Help grow the local economy</p>
                            <h2 style={{ fontSize: '2.4em', marginBottom: '20px' }}>Support local black business</h2>
                            <p>ETA highlights local black-owned businesses and event organizers in our calendars.<br /><br />By using ETA you are supporting these local businesses and helping them grow.</p>
                        </BouncyDiv>
                    </Col>
                </Row>
            </NoSSR>

        </Container>

        <NoSSR>
            <div style={{ marginBottom: !isMobile ? 160 : 60, paddingTop: '60px', paddingBottom: '60px', backgroundColor: "#E9FCFF" }}>
                <Container>
                    <h2 style={{ textAlign: 'center', marginBottom: '20px', fontSize: '35px' }}>We've got fans and experience</h2>
                    <p style={{ textAlign: 'center', marginBottom: '30px', color: 'gray' }}>We've processed over <span style={{ fontWeight: 'bold' }}>100k</span> in ticket sales and plugged in <span style={{ fontWeight: 'bold' }}>4,000+</span> people</p>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>

                        <Col md>
                            <p style={{ marginTop: 0 }}><span style={{ fontSize: '30px', fontWeight: 'bold' }}>"</span>After discovering ETA, I ended up going to some events I never would have heard off. I meet so many amazing people, eat some great food, danced my butt off and had a blast. You all really made the whole trip for me.<br /><span style={{ fontSize: '30px', fontWeight: 'bold' }}>"</span></p>
                        </Col>

                        <Col style={{ textAlign: !isMobile ? 'left' : 'center' }} md>
                            <img src="/static/testimonial.jpg" style={{ height: '300px', borderRadius: 10 }} />
                            <p style={{ marginTop: '10px' }}>Tina <br /> Dallas, TX</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </NoSSR>

        <Container>
            <h2 style={{ textAlign: 'center', fontSize: '35px' }}>Get big benefits for a small price</h2>

            <Row style={{ marginTop: 50, paddingBottom: 60 }}>
                {pricingPlans.map((item, index) => <Col md style={{ display: 'flex', justifyContent: index === 0 ? 'flex-end' : 'flex-start', marginBottom: 10 }}>
                    <div style={{ border: '1px solid', maxWidth: '400px', padding: 20, textAlign: 'center', borderRadius: 10 }}>
                        <p style={{ fontSize: 20 }}>{item.title}</p>

                        <p style={{
                            color: Constants.primaryETAButtonColor,
                            fontSize: 35
                        }}>${item.cost}</p>
                        <p style={{ color: 'gray' }}><small>per day</small></p>

                        {item.features.map((featureItem, index) => <p>{featureItem}</p>)}
                        {item.nonFeatures.map((nonFeature, index) => <p style={{ color: 'lightgray' }}>{nonFeature}</p>)}
                        <Button onClick={() => {
                            window.scrollTo(0, 0);
                            metricsManager.logEvent(PASSPORT_GET_STARTED_CLICK, { source: `price_${item.title}` })
                        }
                        }
                            variant="info">Get started</Button>
                    </div>
                </Col>)}
            </Row>

        </Container>

        <div style={{
            backgroundImage: `url('https://www.witheta.com/static/crowd_1.jpg')`,
            width: '100%',
            height: '400px',
            backgroundSize: 'cover'
        }}>
        </div>

        <NoSSR>
            <Container>
                <BouncyDiv enabled={() => userY > 4000}>
                    <h2 style={{ marginTop: 20, marginBottom: 20, fontSize: '2.6em' }}>The culture is waiting for you</h2>
                    <p>100% refundable if you don't like the guide!</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingBottom: 100 }}>
                        <Button variant="info" onClick={() => {
                            window.scrollTo(0, 0);
                            metricsManager.logEvent(PASSPORT_GET_STARTED_CLICK, { source: "home_bottom" })
                        }
                        }
                            size="lg" style={{ marginRight: 10 }}>Aight let's go!</Button>
                        <Button href="mailto:info@witheta.com" onClick={() => metricsManager.logEvent("PASSPORT_CONTACT_US", {})}
                            variant="outline-primary" size="lg">Contact</Button>
                    </div>
                </BouncyDiv>
            </Container>
        </NoSSR>
        <style jsx global>{`
        html, body {
            max-width: 100%;
            overflow-x: hidden;
            height: auto
        }
        
        #video-content:before {
            content: '';
            position: absolute;
            background: rgba(0, 0, 0, 0.4);
            top: 0;
            height: ${aboveFoldHeight};
            right: 0;
            bottom: 0;
            left: 0;
          }
        
        `}</style>
    </Layout>
}

PassportLandingPage.getInitialProps = async (ctx) => {
    const zoneSlug = ctx.query.zone ? ctx.query.zone : 'default'
    const experience = ctx.query.exp ? ctx.query.exp : null
    const props = { experience: experience }
    if (zoneSlug) {
        const zoneApi = new ZoneAPI();
        props.zoneSlug = zoneSlug
        const zoneResp = await zoneApi.getZone(zoneSlug)
        if (zoneResp && zoneResp.code !== -1) {
            props.zone = zoneResp
        }
    }
    const pageRoll = Math.random() > 0.5;

    var showVideo = true;
    if (experience === "random") {
        const roll = Math.random() > 0.5;
        console.log(`ROLL IS ${roll}`);
        showVideo = roll;
    }
    props.exp = experience;
    props.showVideo = showVideo;

    return props
}

export default PassportLandingPage